import wishlistReducer from "./wishlistReducer";
import filterReducer from "./filterReducer";
import settingsReducer from "./settingsReducer";
import { combineReducers } from "redux";
import overlayReducer from "./overlayReducer";

const rootReducer = combineReducers({
  wishlistData: wishlistReducer,
  filterData: filterReducer,
  settingsData: settingsReducer,
  overlayActive: overlayReducer,
});

export default rootReducer;
