import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import styled from "styled-components";
import { colors } from "../../../utils/theme";

const MobileNavMenuHome = ({ closeMenu }) => {
  const { t } = useTranslation();
  return (
    <StyledNav>
      <ul>
        <li>
          <MenuItem to={urls.home} onClick={closeMenu}>
            {t("home")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.about} onClick={closeMenu}>
            {t("about_us")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.get_inspired} onClick={closeMenu}>
            {t("get_inspired")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.b2b_showroom} onClick={closeMenu}>
            {t("b2b_showroom")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.store_locator} onClick={closeMenu}>
            {t("store_locator")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.contact} onClick={closeMenu}>
            {t("contact_us")}
          </MenuItem>
        </li>
      </ul>
    </StyledNav>
  );
};

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
  margin: 1rem 0;
`;

const MenuItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 3rem 2rem;
`;

export default MobileNavMenuHome;
