import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DIGITAL_KISS_LOGO,
  WEBSITE_CITY,
  WEBSITE_STREET_NUMBER,
  WEBSITE_ZIPCODE,
} from "../../constants/CompanyConstants";
import styled from "styled-components";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      <div className="footer-logo">
        <Link to={"/"}>
          <img alt="logo" src={footerLogo} />
        </Link>
      </div>
      <p className={"mb-0"}>{WEBSITE_STREET_NUMBER}</p>
      <p className={"mb-0"}>
        {WEBSITE_ZIPCODE} {WEBSITE_CITY}
      </p>
      <p>{t("netherlands")}</p>
      <p className={"mb-0"}>{t("powered_by")}</p>
      <a
        href="https://digitalkiss.nl"
        rel="noopener noreferrer"
        target="_blank"
      >
        <DigitalKissLogo src={DIGITAL_KISS_LOGO} />
      </a>
    </div>
  );
};

const DigitalKissLogo = styled.img`
  width: 120px;
`;

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
