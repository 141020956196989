import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../../translations/i18n";
import styled from "styled-components";
import { Select } from "antd";

const { Option } = Select;

const MobileLanguageChanger = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const handleChangeLanguage = (lang) => {
    changeLanguage(lang);
  };

  return (
    <Wrapper>
      <span style={{ marginBottom: ".5rem" }}>{t("choose_language")}</span>
      <Select onChange={handleChangeLanguage} value={lang}>
        <Option value={"nl"}>Nederlands</Option>
        <Option value={"en"}>English</Option>
        <Option value={"de"}>Deutsch</Option>
      </Select>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem;

  span {
    font-size: 16px;
  }

  .ant-select-focused {
    span:first-child {
      margin: 0;
    }
  }
`;

MobileLanguageChanger.propTypes = {
  currentLanguageCode: PropTypes.string,
};

export default MobileLanguageChanger;
