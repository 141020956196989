import React from "react";
import { StyledSvgIcon } from "../../utils/sharedStyles";

const CartIcon = ({ width = 22 }) => {
  return (
    <StyledSvgIcon viewBox="0 0 21 21" style={{ width: width }}>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" fill="#807159" fillRule="nonzero">
          <path
            d="M8.03,12.9 L20.33,9.39 C20.56,9.33 20.76,9.07 20.76,8.82 L20.76,2.71 L5.09,2.71 L5.09,0.82 C5.09,0.57 4.89,0.38 4.64,0.38 L1.2,0.38 C0.95,0.38 0.75,0.58 0.75,0.82 L0.75,2.59 L2.91,2.59 L5.1,12.66 L5.31,13.71 L5.31,15.38 C5.31,15.63 5.51,15.83 5.75,15.83 L20.31,15.83 C20.55,15.83 20.75,15.63 20.75,15.38 L20.75,13.71 L8.25,13.71 C6.97,13.71 6.95,13.21 8.02,12.9 L8.03,12.9 Z"
            id="Path"
          />
          <path
            d="M17.43,15.94 C16.2,15.94 15.21,16.93 15.21,18.16 C15.21,19.39 16.2,20.38 17.43,20.38 C18.66,20.38 19.65,19.39 19.65,18.16 C19.65,16.93 18.66,15.94 17.43,15.94 Z"
            id="Path"
          />
          <path
            d="M6.31,20.38 C7.54,20.38 8.53,19.39 8.53,18.16 C8.53,16.93 7.53,15.94 6.31,15.94 C5.09,15.94 4.09,16.93 4.09,18.16 C4.09,19.39 5.09,20.38 6.31,20.38 Z"
            id="Path"
          />
        </g>
      </g>
    </StyledSvgIcon>
  );
};

export default CartIcon;
