import _ from "lodash";

export const getProductCollection = (product, collections) => {
  const productCollection = _.find(
    collections,
    (col) => !!col.products && col.products.includes(product.id)
  );
  return !!productCollection ? productCollection : null;
};

export const isCollectionVisibleForGroup = (collection, groupId) => {
  if (!!collection && !!collection.hiddenForGroups) {
    return !collection.hiddenForGroups.includes(groupId);
  } else return true;
};
