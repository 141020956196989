// Importeer de benodigde functies van de Firebase libraries
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Je Firebase configuratie
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGESENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialiseer Firebase
const app = initializeApp(firebaseConfig);

// Initialiseer Authentication
let auth = getAuth(app);

// Initialiseer Firestore
const db = getFirestore(app);

// Initialiseer Analytics
const analytics = getAnalytics(app);

// Exporteer de geïnitialiseerde Firebase services
export { app, auth, db, analytics };
