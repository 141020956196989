import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./translations/i18n";
import urls from "./urls";
import ForgotPassword from "./pages/other/ForgotPassword";
import { Loader } from "./components/loader/Loader";
import moment from "moment";
import localization from "moment/locale/nl";
import CookieBanner from "./components/banner/CookieBanner";
import PrivateRoute from "./PrivateRoute";
import SessionTracker from "./components/session/SessionTracker";
import { colors } from "./utils/theme";
import { ConfigProvider } from "antd";
import "dayjs/locale/nl";
import locale from "antd/locale/nl_NL";
import { auth } from "./services/firebase";
import ScrollToTop from "./components/global/scrollToTop";

moment.locale("nl", localization);

const Home = lazy(() => import("./pages/home/Home"));
// shop pages
const Dashboard = lazy(() => import("./pages/shop/Dashboard"));
const ShopListStandard = lazy(() => import("./pages/shop/ShopList"));
const CategoryLandingPage = lazy(() => import("./pages/shop/CategoryLanding"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const VerifyEmail = lazy(() => import("./pages/other/VerifyEmail"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderConfirmed = lazy(() => import("./pages/other/OrderConfirmed"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const OrderProcessing = lazy(() => import("./pages/other/OrderProcessing"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/other/TermsConditions"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

// Bombyxx Pages
const StoreLocator = lazy(() => import("./pages/bombyxx/StoreLocator"));
const B2BShowroom = lazy(() => import("./pages/bombyxx/Showroom"));
const GetInspired = lazy(() => import("./pages/bombyxx/GetInspired"));
const Vacatures = lazy(() => import("./pages/bombyxx/Vacatures"));

const clearFirestoreCache = () => {
  const map = globalThis["_reactFirePreloadedObservables"];
  Array.from(map.keys()).forEach(
    (key) => key.includes("firestore") && map.delete(key),
  );
};

const App = () => {
  useEffect(() => {
    // Clear firestore cache to prevent insufficient permissions bug
    const unsub = auth.beforeAuthStateChanged((user) => {
      clearFirestoreCache();
    });
    return () => unsub();
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <SessionTracker />
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: colors.cta_btn,
              fontFamily: "",
            },
          }}
        >
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* Homepage */}
              <Route exact path={urls.home} component={Home} />

              {/* Shop pages */}
              <PrivateRoute
                exact
                path={urls.shopLandingPage}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path={urls.shop}
                component={ShopListStandard}
              />
              <PrivateRoute
                exact
                path={urls.productsByCategory}
                component={ShopListStandard}
              />

              {/* Shop product pages */}
              <PrivateRoute
                path={urls.productDetail}
                detailPage
                render={(routeProps) => (
                  <Product
                    {...routeProps}
                    key={routeProps.match.params.id}
                    clickedProduct={routeProps.location.product}
                  />
                )}
              />

              {/* Category landing page */}
              <PrivateRoute
                exact
                path={urls.categoryLandingPage}
                component={CategoryLandingPage}
              />
              <Route
                exact
                path={urls.subcategoryLandingPage}
                component={CategoryLandingPage}
              />

              {/* Other pages */}
              <Route path={urls.about} component={About} />
              <Route path={urls.contact} component={Contact} />
              <PrivateRoute path={urls.my_account} component={MyAccount} />
              <PrivateRoute path={urls.my_orders} component={MyOrders} />
              <Route path={urls.login_register} component={LoginRegister} />
              <Route path={urls.forgot_password} component={ForgotPassword} />
              <Route path={urls.email_verified} component={VerifyEmail} />
              <Route path={urls.privacy} component={PrivacyPolicy} />

              <PrivateRoute path={urls.cart} component={Cart} />
              <PrivateRoute path={urls.wishlist} component={Wishlist} />
              <PrivateRoute path={urls.checkout} component={Checkout} />
              <PrivateRoute
                path={urls.order_confirmed}
                component={OrderConfirmed}
              />
              <PrivateRoute path={urls.order_failed} component={OrderFailed} />
              <PrivateRoute
                path={urls.order_processing + urls.document_id_param}
                component={OrderProcessing}
              />
              <Route path={urls.not_found} component={NotFound} />
              <Route path={urls.terms_conditions} component={TermsConditions} />

              <Route path={urls.store_locator} component={StoreLocator} />
              <Route path={urls.b2b_showroom} component={B2BShowroom} />
              <Route path={urls.get_inspired} component={GetInspired} />
              <Route path={urls.vacancies} component={Vacatures} />

              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ConfigProvider>
        <CookieBanner />
      </Router>
    </>
  );
};

export default App;
