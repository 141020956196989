import {
  DELETE_FILTERS,
  RESET_FILTER,
  SAVE_FILTERS,
} from "../actions/filterActions";

const initState = [];

const filterReducer = (state = initState, action) => {
  const filters = state,
    filter = action.payload;

  if (action.type === SAVE_FILTERS) {
    return { ...filters, [filter.filterType]: filter.filterValue };
  }

  if (action.type === DELETE_FILTERS) {
    return { new: false, preOrder: true };
  }

  if (action.type === RESET_FILTER) {
    if (!!filter.filterValue)
      return { ...filters, [filter.filterType]: filter.filterValue };
    else return { ...filters, [filter.filterType]: null };
  }

  return filters;
};

export default filterReducer;
