import styled from "styled-components";
import { colors } from "./theme";
import { Breadcrumb } from "antd";
import { device } from "./breakpoints";

export const StyledSvgIcon = styled.svg`
  g,
  path {
    transition: fill 0.2s ease;
  }

  &:hover {
    cursor: pointer;

    g,
    path {
      fill: ${colors.primary80};
    }
  }
`;

export const StyledH4 = styled.h4`
  text-transform: uppercase;
  color: ${colors.header};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin: 0;

  @media ${device.lg} {
    font-size: 20px;
  }
`;

export const ShopFilterTitle = styled(StyledH4)`
  text-transform: uppercase;
  color: ${colors.header};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.8px;
  margin: 0;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  padding-block: 2rem;

  li {
    display: flex;
    align-items: center;

    a,
    span {
      font-size: 12px;
      color: ${colors.text} !important;
      display: flex;
      align-items: center;
    }
  }
`;
