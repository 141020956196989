import React from "react";
import { StyledSvgIcon } from "../../utils/sharedStyles";

const WishlistIcon = ({ width = 22 }) => {
  return (
    <StyledSvgIcon viewBox="0 0 20 16" style={{ width: width }}>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M18.3579105,8.46307385 C20.5473632,6.48794718 20.5473632,3.29433441 18.3579105,1.31920774 C16.4183954,-0.439735913 13.2691827,-0.439735913 11.3196701,1.31920774 L10,2.50821434 L8.68032992,1.31920774 C6.7308173,-0.439735913 3.5816046,-0.439735913 1.64208948,1.31920774 C-0.547363159,3.29433441 -0.547363159,6.48794718 1.64208948,8.46307385 L10,16 L18.3579105,8.46307385 Z"
          id="Path"
          fill="#807159"
          fillRule="nonzero"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default WishlistIcon;
