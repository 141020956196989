import {
  SAVE_SHOPPING_FOR_GROUP,
  RESET_SHOPPING_FOR_GROUP,
} from "../actions/settingsActions";

const initState = { group: null };

const settingsReducer = (state = initState, action) => {
  const settings = state,
    setting = action.payload;

  if (action.type === SAVE_SHOPPING_FOR_GROUP) {
    return { ...settings, group: setting.group };
  }

  if (action.type === RESET_SHOPPING_FOR_GROUP) {
    return { ...settings, group: null };
  }

  return settings;
};

export default settingsReducer;
