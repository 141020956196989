import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import {
  SOCIAL_MEDIA,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from "../../constants/CompanyConstants";
import FooterOpeningHours from "../../components/footer/FooterOpeningHours";
import openingHoursData from "../../data/company/opening_hours.json";
import FooterBottom from "../../components/footer/FooterBottom";
import { colors } from "../../utils/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterHome = ({ containerClass }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <footer className={"footer-area pt-70"}>
      <div className={`${containerClass ? containerClass : "container-fluid"}`}>
        <div className="row">
          <div className={"col-lg-1 .d-none .d-lg-block .d-xl-block"} />
          <div className={"col-lg-2 col-sm-12"}>
            {/* footer copyright */}
            <FooterCopyright
              footerLogo={WEBSITE_LOGO}
              spaceBottomClass="mb-30"
            />
          </div>
          <div className={"col-lg-1 .d-none .d-lg-block .d-xl-block"} />
          <div className={"col-lg-2 col-sm-12"}>
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{WEBSITE_NAME}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={urls.home}>{t("home")}</Link>
                  </li>
                  <li>
                    <Link to={urls.about}>{t("about_us")}</Link>
                  </li>
                  <li>
                    <Link to={urls.contact}>{t("contact")}</Link>
                  </li>
                  <li>
                    <Link to={urls.vacancies}>{t("vacancies")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-2 col-sm-12"}>
            <div className={"footer-widget mb-30 ml-50"}>
              <div className="footer-title">
                <h3>{t("dealership")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={urls.shop}>{t("b2b_webshop")}</Link>
                  </li>
                  <li>
                    <Link to={urls.b2b_showroom}>{t("b2b_showroom")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"footer-widget mb-30 ml-50"}>
              <div className="footer-title">
                <h3>{t("consumers")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={urls.get_inspired}>{t("get_inspired")}</Link>
                  </li>
                  <li>
                    <Link to={urls.store_locator}>{t("store_locator")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-4 col-sm-12"}>
            <div className={"footer-widget mb-30 ml-75"}>
              <FooterOpeningHours data={openingHoursData[lang]} />
              <div className="footer-list">
                <ul>
                  {SOCIAL_MEDIA.length > 0 && (
                    <div className="off-canvas-widget-social mt-5">
                      {SOCIAL_MEDIA.map((item, key) => {
                        return (
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"pl-2 pr-2"}
                            key={key}
                            style={{ color: colors.primaryDark }}
                          >
                            <FontAwesomeIcon icon={item.icon} />
                          </a>
                        );
                      })}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className={"col-lg-4 col-sm-6"} />
          <FooterBottom />
        </div>
      </div>
    </footer>
  );
};

FooterHome.propTypes = {
  containerClass: PropTypes.string,
};

export default FooterHome;
