import React from "react";
import MobileNavMenuHome from "./sub-components/MobileNavMenuHome";
import MobileLangCurChange from "./sub-components/MobileLanguageChanger";
import MobileWidgets from "./sub-components/MobileWidgets";
import MobileNavMenuShop from "./sub-components/MobileNavMenuShop";
import styled from "styled-components";

const MobileMenu = ({ shop, active, closeMenu }) => {
  return (
    <Wrapper className={active ? "active" : ""}>
      <Content>
        {/* mobile nav menu */}
        {shop ? (
          <MobileNavMenuShop closeMenu={closeMenu} />
        ) : (
          <MobileNavMenuHome closeMenu={closeMenu} />
        )}
        <Divider />
        {/* mobile language and currency */}
        <MobileLangCurChange />
        <Divider />
        {/* mobile widgets */}
        <MobileWidgets />
        {/*<StyleElement>*/}
        {/*  <img*/}
        {/*    src={"assets/img/illustrations/smiles-of-nature.webp"}*/}
        {/*    alt={""}*/}
        {/*  />*/}
        {/*</StyleElement>*/}
      </Content>
    </Wrapper>
  );
};

const StyleElement = styled.div`
  position: absolute;
  bottom: 7rem;
  right: 1rem;

  img {
    max-width: 65vw;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  top: 54px;
  background: #fff;
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  transform: translate(-100%, 0);
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.active {
    transform: translate(0, 0);
  }
`;

export default MobileMenu;
