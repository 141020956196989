import React, { lazy, useEffect } from "react";
import { Route } from "react-router-dom";
import { useUser } from "reactfire";
import { useUserProfile } from "./components/hooks/firestore/useUserProfile";
import { auth } from "./services/firebase";
import { signOut } from "firebase/auth";

const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const PrivateRoute = ({
  exact = true,
  path,
  component,
  detailPage = false,
  render,
}) => {
  const { data: authUser, status: authStatus } = useUser();
  const { data: profile, isLoading: profileLoading } = useUserProfile();
  const isAuthenticated =
    authStatus === "success" && !!authUser && !!authUser.uid;
  const authorizationLoaded = !profileLoading;
  const isAuthorized =
    authorizationLoaded && profile?.authorization?.isAuthorized;

  useEffect(() => {
    if (authorizationLoaded && !isAuthorized) signOut(auth);
  }, []);

  if (detailPage)
    return (
      <Route
        exact={exact}
        path={path}
        component={
          authorizationLoaded
            ? isAuthenticated && isAuthorized
              ? component
              : LoginRegister
            : isAuthenticated
              ? component
              : LoginRegister
        }
        render={render}
      />
    );
  else
    return (
      <Route
        exact={exact}
        path={path}
        component={
          authorizationLoaded
            ? isAuthenticated && isAuthorized
              ? component
              : LoginRegister
            : isAuthenticated
              ? component
              : LoginRegister
        }
      />
    );
};

export default PrivateRoute;
