import { DESCRIPTION_LENGTH } from "../constants/ProductConstants";
import moment from "moment";
import i18n from "i18next";
import {
  CATEGORY_ACCESSORIES,
  CATEGORY_BLACK_FRIDAY,
  CATEGORY_GLASS,
  CATEGORY_LAMPS,
  CATEGORY_MOUTH_VASES,
  CATEGORY_PLATES,
  CATEGORY_SALE,
  CATEGORY_TABLES,
} from "../constants/CategoryConstants";

export const formatPrice = function (value) {
  if (!!value)
    return value.toLocaleString("nl-NL", {
      minimumFractionDigits: value % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  else return 0;
};

export const formatWeight = function (value) {
  value = parseInt(value);
  return value.toLocaleString("nl-NL", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const formatDescription = function (value) {
  return value.length > DESCRIPTION_LENGTH
    ? value.substring(0, DESCRIPTION_LENGTH - 3) + "..."
    : value.substring(0, DESCRIPTION_LENGTH);
};

export const formatOrderDate = (timestamp) => {
  return moment.unix(timestamp).format("DD/MM/YYYY");
};

export const formatInvoiceFileName = (invoice, orderId) => {
  return invoice + "-" + orderId + ".pdf";
};

export const formatNameToURL = (name) => {
  if (!name) return null;
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  let to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  // collapse whitespace and replace by -
  // collapse dashes
  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
};

export const formatUrlToName = (url) => {
  if (!url) return null;

  // Replace dashes with spaces
  url = url
    .replace(/^\s+|\s+$/g, "")
    .replace(/-/g, " ")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return url;
};

/**
 * Rounds float to 2 decimals
 * @param {*} value The number to be converted
 * @returns Number rounded to two decimals
 */
export const roundPrice = (value) => {
  return Math.round(value * 100) / 100;
};

/**
 * Turn variation into a title that can be used in JSX
 * */
export const formatVariationToTitle = (variation) => {
  switch (variation.type) {
    case "WEIGHT":
      return variation.weight + " gram";
    default:
      return variation.weight + " gram";
  }
};

/**
 * Turn minAmount and orderUnit into readable string for cart
 * */
export const formatProductMinAmountAndOrderUnit = (minAmount, orderUnit) => {
  minAmount = minAmount || 1;
  orderUnit = orderUnit || 1;
  if (minAmount > 1 && orderUnit > 1)
    return i18n.t("product_per_order_unit_from_min_amount", {
      orderUnit: orderUnit,
      minAmount: minAmount,
    });
  else if (minAmount > 1 && orderUnit === 1)
    return i18n.t("product_from_min_amount", { minAmount: minAmount });
  else if (minAmount === 1 && orderUnit > 1)
    return i18n.t("product_per_order_unit", { orderUnit: orderUnit });
};

export const formatDimension = (dimension) => {
  return dimension[0] + "cm - " + dimension[1] + "cm";
};

export const formatCategory = (cat, lang) => {
  if (lang === "nl") {
    switch (cat) {
      case CATEGORY_GLASS:
        return "Glaswerk";
      case CATEGORY_LAMPS:
        return "Lampen";
      case CATEGORY_MOUTH_VASES:
        return "Toetervazen";
      case CATEGORY_PLATES:
        return "Dienbladen";
      case CATEGORY_SALE:
        return "Uitverkoop";
      case CATEGORY_ACCESSORIES:
        return "Accessoires";
      case CATEGORY_TABLES:
        return "Tafels";
      case CATEGORY_BLACK_FRIDAY:
        return "Black Friday";
      default:
        return cat;
    }
  } else if (lang === "en") {
    switch (cat) {
      case CATEGORY_GLASS:
        return "Glass";
      case CATEGORY_LAMPS:
        return "Lamps";
      case CATEGORY_MOUTH_VASES:
        return "Mouth vases";
      case CATEGORY_PLATES:
        return "Plates";
      case CATEGORY_SALE:
        return "Sale";
      case CATEGORY_ACCESSORIES:
        return "Accessories";
      case CATEGORY_TABLES:
        return "Tables";
      case CATEGORY_BLACK_FRIDAY:
        return "Black Friday";
      default:
        return cat;
    }
  } else if (lang === "de") {
    switch (cat) {
      case CATEGORY_GLASS:
        return "Glaswaren";
      case CATEGORY_LAMPS:
        return "Lampen";
      case CATEGORY_MOUTH_VASES:
        return "Hornvasen";
      case CATEGORY_PLATES:
        return "Tabletts";
      case CATEGORY_SALE:
        return "Ausverkauf";
      case CATEGORY_ACCESSORIES:
        return "Zubehör";
      case CATEGORY_TABLES:
        return "Tische";
      case CATEGORY_BLACK_FRIDAY:
        return "Black Friday";
      default:
        return cat;
    }
  } else return cat;
};

export const formatCategoryName = (cat, lang) => {
  if (!!cat && !!lang) {
    if (lang === "nl") return cat.name;
    else if (lang === "de") return cat.nameDE;
    else return cat.nameEN;
  } else return "";
};
