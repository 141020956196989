export const colors = {
  primary: "#846f55",
  primary80: "#A8977C",
  primary25: "#e0dbd4",
  primaryLight: "#EEEAE4",
  primaryDark: "#3C3C3B",
  secondary: "#846f55",
  text: "#707070",
  btn: "#4B5A57",
  cta_btn: "#A74C00",
  pagination: "#A74C00",
  cta_btn_text: "#fff",
  standout_text: "#579b3c",
  added_to_cart_btn: "#009d00",
  category_header: "#e5e5e5",
  clickable_text: "#0066ff",
  pre_order: "red",
  pre_order_text: "white",
  hamburger: "#846f55",
  menu_items: "#222",
  header: "#58564F",
  lightHeader: "#D4D0CA",
  bg: "#F5EEE6",
};

export const styles = {
  btn_border_radius: "5px",
  card_border_radius: "10px",
  bullet_point_icon: "💎  ",
};
