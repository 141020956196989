import { TOGGLE_OVERLAY } from "../actions/overlayActions";

const initState = false;

const overlayReducer = (state = initState, action) => {
  const view = state,
    boolean = action.payload;

  if (action.type === TOGGLE_OVERLAY) {
    return boolean;
  }

  return view;
};

export default overlayReducer;
