import React from "react";
import { SOCIAL_MEDIA } from "../../../constants/CompanyConstants";
import styled from "styled-components";

const MobileWidgets = () => {
  return (
    <Wrapper>
      {SOCIAL_MEDIA.length > 0 && (
        <IconsWrapper>
          {SOCIAL_MEDIA.map((item, key) => {
            return (
              <a
                href={item.link}
                key={key}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className={item.icon} />
              </a>
            );
          })}
        </IconsWrapper>
      )}
    </Wrapper>
  );
};

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
  }
  i {
    font-size: 28px;
  }
`;

const Wrapper = styled.div`
  padding: 2rem 2rem;
`;

export default MobileWidgets;
