import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenuHome from "../../components/header/NavMenuHome";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import { WEBSITE_LOGO } from "../../constants/CompanyConstants";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { home } from "../../urls";
import { motion } from "framer-motion";
import useIsMobile from "../../components/hooks/useIsMobile";

const headerVariants = {
  visible: {
    translateY: 0,
  },
  hidden: {
    translateY: "-100%",
  },
};

const HeaderHome = ({ borderStyle }) => {
  const isMobile = useIsMobile();
  const [menuOpen, setMenuOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [y, setY] = useState(
    typeof window !== "undefined" ? window.scrollY : 0,
  );

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      const threshold = isMobile ? 20 : 70;
      if (y > window.scrollY || menuOpen) {
        setShow(true);
      } else if (y < window.scrollY && y > threshold) {
        setShow(false);
      }
      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      {!isMobile && (
        <DesktopHeader
          className={"header-area clearfix"}
          animate={show ? "visible" : "hidden"}
          variants={headerVariants}
          transition={{
            ease: "easeOut",
            duration: 0.5,
          }}
        >
          <div className={"header-top-area sticky-bar"}>
            {/* header top */}
            <HeaderTop borderStyle={borderStyle} />
          </div>

          <div
            className={"sticky-bar home header-res-padding clearfix bg-shadow"}
          >
            <div className={"container-fluid"}>
              <div className="row">
                <div className="col-lg-2 col-md-6 col-4 d-block d-lg-none">
                  {/* header logo */}
                  <Logo imageUrl={WEBSITE_LOGO} logoClass="logo" />
                </div>
                <div className="col-lg-12 d-none d-lg-block">
                  {/* Nav menu */}
                  <NavMenuHome />
                </div>
                <div className="col-lg-2 col-md-6 col-8 d-block d-lg-none">
                  {/* Icon group */}
                  <IconGroup homeLayout />
                </div>
              </div>
            </div>
          </div>
        </DesktopHeader>
      )}
      {isMobile && (
        <MobileHeader
          animate={show ? "visible" : "hidden"}
          variants={headerVariants}
          transition={{
            ease: "easeOut",
            duration: 0.5,
          }}
        >
          {/* header top */}
          <HeaderTop borderStyle={borderStyle} />
          <div
            className={"sticky-bar clearfix bg-shadow"}
            style={{ padding: "16px 0 10px" }}
          >
            <MobileItemsWrapper>
              <HamburgerMenu
                onClick={() => setMenuOpen(!menuOpen)}
                className={menuOpen ? "active" : ""}
              >
                {/*<input />*/}
                <span />
                <span />
                <span />
              </HamburgerMenu>
              {/* header logo */}
              <Logo
                imageUrl={WEBSITE_LOGO}
                logoClass="logo"
                link={home}
                closeMenu={closeMenu}
              />
              <div />
            </MobileItemsWrapper>
            {/* mobile menu */}
            <MobileMenu active={menuOpen} closeMenu={closeMenu} />
          </div>
        </MobileHeader>
      )}
    </>
  );
};

const HamburgerMenu = styled.div`
  display: block;
  position: relative;
  z-index: 1;

  -webkit-user-select: none;
  user-select: none;

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;

    background: ${colors.hamburger};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition:
      transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  &.active {
    span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -3px);
      background: ${colors.hamburger};
    }

    span:nth-last-child(2) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    span:nth-last-child(1) {
      transform: rotate(-45deg) translate(0, -1px);
    }
  }
`;

const MobileItemsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
`;

const MobileHeader = styled(motion.header)`
  display: block;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  width: 100%;
  @media ${device.lg} {
    display: none;
  }
`;

const DesktopHeader = styled(motion.header)`
  display: none;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  width: 100%;

  @media ${device.lg} {
    display: block;
  }
`;

export default HeaderHome;
