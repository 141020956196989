import React from "react";
import { StyledSvgIcon } from "../../utils/sharedStyles";

const AccountIcon = ({ width = 22 }) => {
  return (
    <StyledSvgIcon viewBox="0 0 20 19" style={{ width: width }}>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M17.49,14.2576 C16.27,13.6394667 14.49,12.1397333 11.84,11.6432 C12.49,10.8426667 13.01,9.6064 13.57,8.15733333 C13.84,7.28586667 13.8,6.5664 13.8,5.54293333 C13.8,4.7728 13.95,3.57706667 13.75,2.888 C13.17,0.618133333 11.7,0 9.98,0 C8.26,0 6.75,0.618133333 6.18,2.888 C6.01,3.5872 6.13,4.8032 6.13,5.54293333 C6.13,6.55626667 6.08,7.27573333 6.4,8.15733333 C6.93,9.6064 7.43,10.8629333 8.1,11.6432 C5.47,12.16 3.67,13.6597333 2.5,14.2576 C0,15.4432 0,16.7605333 0,16.7605333 L0,17.2672 C0,18.1285333 0.93,19 1.83,19 L18.15,19 C19.3,19 20,18.0474667 20,17.1861333 L20,16.7504 C20,16.7504 19.97,15.4330667 17.5,14.2474667 L17.49,14.2576 Z"
          id="Path"
          fill="#807159"
        />
      </g>
    </StyledSvgIcon>
  );
};

export default AccountIcon;
