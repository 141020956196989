export const SAVE_FILTERS = "SAVE_FILTERS";
export const DELETE_FILTERS = "DELETE_FILTERS";
export const RESET_FILTER = "RESET_FILTER";

// save all product filters
export const saveFilters = (filterType, filterValue) => {
  return (dispatch) => {
    dispatch({ type: SAVE_FILTERS, payload: { filterType, filterValue } });
  };
};

// delete all product filters
export const deleteFilters = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_FILTERS });
  };
};

// Reset specific filter
export const resetFilter = (filterType, filterValue) => {
  return (dispatch) => {
    dispatch({ type: RESET_FILTER, payload: { filterType, filterValue } });
  };
};
