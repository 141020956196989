import React from "react";
import { Button } from "antd";

const AntdCtaBtn = ({ type = "primary", children, ...rest }) => {
  return (
    <Button type={type} {...rest}>
      {children}
    </Button>
  );
};

export default AntdCtaBtn;
