import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls from "../../../urls";
import styled from "styled-components";
import { formatCategory } from "../../../helpers/formatters";
import {
  CATEGORY_ACCESSORIES,
  CATEGORY_GLASS,
  CATEGORY_LAMPS,
  CATEGORY_MOUTH_VASES,
  CATEGORY_PLATES,
  CATEGORY_SALE,
  CATEGORY_TABLES,
} from "../../../constants/CategoryConstants";
import { colors } from "../../../utils/theme";

const MobileNavMenuShop = ({ closeMenu }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <StyledNav>
      <ul>
        <li>
          <MenuItem to={urls.shop} onClick={closeMenu}>
            {t("all_products")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "glass"} onClick={closeMenu}>
            {formatCategory(CATEGORY_GLASS, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "lamps"} onClick={closeMenu}>
            {formatCategory(CATEGORY_LAMPS, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "mouth-vases"} onClick={closeMenu}>
            {formatCategory(CATEGORY_MOUTH_VASES, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "plates"} onClick={closeMenu}>
            {formatCategory(CATEGORY_PLATES, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "tables"} onClick={closeMenu}>
            {formatCategory(CATEGORY_TABLES, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "accessories"} onClick={closeMenu}>
            {formatCategory(CATEGORY_ACCESSORIES, lang)}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.categorySlug + "sale"} onClick={closeMenu}>
            {formatCategory(CATEGORY_SALE, lang)}
          </MenuItem>
        </li>
        <Divider />
        <li>
          <MenuItem to={urls.my_account} onClick={closeMenu}>
            {t("my_account")}
          </MenuItem>
        </li>
        <li>
          <MenuItem to={urls.my_orders} onClick={closeMenu}>
            {t("my_orders")}
          </MenuItem>
        </li>
      </ul>
    </StyledNav>
  );
};

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
  margin: 1rem 0;
`;

const MenuItem = styled(Link)`
  display: block;
  font-size: 20px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.65rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 3rem 2rem;
`;

export default MobileNavMenuShop;
