export const SAVE_SHOPPING_FOR_GROUP = "SAVE_SHOPPING_FOR_GROUP";
export const RESET_SHOPPING_FOR_GROUP = "RESET_SHOPPING_FOR_GROUP";

export const saveShoppingForGroup = (group) => {
  return (dispatch) => {
    dispatch({ type: SAVE_SHOPPING_FOR_GROUP, payload: { group } });
  };
};

export const resetShoppingForGroup = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SHOPPING_FOR_GROUP });
  };
};
