let paymentCheckout =
  process.env.REACT_APP_API_URL + "/api/payment/stripe/checkout";
let mailService = process.env.REACT_APP_API_URL + "/api/email";
let authService = process.env.REACT_APP_API_URL + "/api/auth";
let exactService = process.env.REACT_APP_API_URL + "/api/exact";
let sessionService = process.env.REACT_APP_API_URL + "/api/sessions";
module.exports = {
  home: "/",
  about: "/about-us",
  contact: "/contact",
  my_account: "/my-account",
  my_orders: "/my-orders",
  login_register: "/login-register",
  forgot_password: "/reset-password",
  cart: "/cart",
  wishlist: "/favorites",
  checkout: "/checkout",
  document_id_param: ":documentId",
  order_processing: "/order/processing/",
  order_confirmed: "/order/confirmed",
  order_failed: "/order/failed",
  not_found: "/not-found",
  blog: "/blog",
  shopLandingPage: "/shop",
  shop: "/products",
  productsByCategory: "/products/:category",
  newProducts: "/products/new",
  product: "/product/",
  productDetail: "/product/:url",
  categorySlug: "/category/",
  categoryLandingPage: "/category/:category",
  subcategoryLandingPage: "/category/:category/:subcategory",
  privacy: "/policies/privacy",
  ideal_checkout: paymentCheckout + "/ideal",
  credit_card_checkout: paymentCheckout + "/creditcard",
  ban_contact_checkout: paymentCheckout + "/bancontact",
  order_confirmed_email: mailService + "/order/confirmed",
  order_processing_redirect_success: "?redirect_status=succeeded",
  order_processing_redirect_failed: "?redirect_status=failed",
  contact_email: mailService + "/contact",
  showroom_email: mailService + "/showroom",
  order_invoice: exactService + "/order/invoice",
  welcome_email: mailService + "/signup/welcome",
  verify_email: mailService + "/signup/verify",
  account_review_email: mailService + "/signup/review/pending",
  email_verified: "/email/verificatie/:encodedEmail",
  email_verified_link: "/email/verificatie/",
  verify_user_email: authService + "/verify/",
  faq: "/faq",
  store_locator: "/store-locator",
  b2b_showroom: "/b2b-showroom",
  get_inspired: "/get-inspired",
  vacancies: "/vacatures",
  terms_conditions: "/policies/terms-of-service",
  save_order_exact: exactService + "/sales_order",
  create_session: sessionService + "/create",
  end_session: sessionService + "/end",
  update_session: sessionService + "/update",
};
