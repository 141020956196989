import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { Container } from "react-bootstrap";
import { SOCIAL_MEDIA } from "../../constants/CompanyConstants";
import { transparentize } from "../../utils/helperFn";
import CtaLink from "../cta/CtaLink";
import { shopLandingPage } from "../../urls";
import { useTranslation } from "react-i18next";
import LanguageChanger from "./sub-components/LanguageChanger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderTop = () => {
  const { t } = useTranslation();
  return (
    <Wrapper fluid>
      {SOCIAL_MEDIA.length > 0 && (
        <SocialIconsWrapper>
          {SOCIAL_MEDIA.map((item, key) => {
            return (
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={"d-flex"}
                key={key}
              >
                <Icon icon={item.icon} />
              </a>
            );
          })}
        </SocialIconsWrapper>
      )}
      <LanguageChanger small topHeader />
      <WebshopButton to={shopLandingPage}>
        {t("b2b_webshop")}
        <i
          className="fa fa-caret-right"
          style={{ fontSize: 16, marginLeft: 6 }}
        />
      </WebshopButton>
    </Wrapper>
  );
};

const WebshopButton = styled(CtaLink)`
  padding: 2px 10px;
  white-space: nowrap;
  margin-left: 1rem;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  align-self: center;
  margin-right: 1rem;
  color: ${colors.primaryDark};
  transition: all 0.2s ease;
  padding-left: 6px;
  padding-right: 6px;

  &:hover {
    color: ${colors.primary};
  }
`;

const SocialIconsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled(Container)`
  display: flex;
  background-color: ${transparentize(colors.primary, 0.25)};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export default HeaderTop;
