// Meta tags titles
export const META_ABOUT_TITLE = "Over Ons";
export const META_CHECKOUT_TITLE = "Afrekenen";
export const META_CONTACT_TITLE = "Contact";
export const META_CART_TITLE = "Winkelwagen";
export const META_RESET_PASSWORD_TITLE = "Wachtwoord Resetten";
export const META_LOGIN_REGISTER_TITLE = "Inloggen - Registreren";
export const META_MY_ACCOUNT_TITLE = "Mijn Account";
export const META_ORDERS_TITLE = "Bestellingen";
export const META_NOT_FOUND_TITLE = "Niet Gevonden";
export const META_ORDER_CONFIRMED_TITLE = "Bestelling Bevestigd";
export const META_ORDER_FAILED_TITLE = "Betaling Mislukt";
export const META_WISHLIST_TITLE = "Favorieten";
export const META_PRIVACY_TITLE = "Privacy Beleid";
export const META_TERMS_CONDITIONS_TITLE = "Algemene Voorwaarden";

export const META_STORE_LOCATOR_TITLE = "Store Locator";
export const META_B2B_SHOWROOM_TITLE = "B2B Showroom";
export const META_GET_INSPIRED_TITLE = "Get Inspired";
export const META_VACATURES_TITLE = "Vactures";

// Meta tags descriptions
export const META_GENERIC_DESCRIPTION =
  "Bombyxx is een merk in exclusieve, duurzame en karakteristieke mondgeblazen glascollecties. Iedere vaas, schaal, pot, windlicht en waxine houder wordt met de hand gemaakt en is een waar meesterwerk.";
export const META_PRODUCT_DESCRIPTION =
  "bij Bombyxx. Puur vakmanschap in een duurzaam jasje. Dé eyecatcher van jouw interieur";
export const META_ABOUT_DESCRIPTION =
  "Bombyxx is een Nederlands merk dat een exclusieve, duurzame en onderscheidende collectie mondgeblazen glas aanbiedt. Wij zorgen ervoor dat de eyecatcher van je interieur er mooi en natuurlijk uitziet. Bombyxx glaswerk is in alle opzichten uniek en tilt je interieur naar een hoger niveau.";
export const META_CONTACT_DESCRIPTION =
  "Vragen of opmerkingen over Bombyxx? Vul het contactformulier in en wij nemen direct contact met je op!";
export const META_RESET_PASSWORD_DESCRIPTION =
  "Je wachtwoord vergeten? Vraag direct een nieuwe aan via deze pagina.";
export const META_LOGIN_REGISTER_DESCRIPTION =
  "Klant bij Bombyxx? Log in om al je bestelling te plaatsen of je vorige bestellingen in te zien.";
export const META_ORDERS_DESCRIPTION =
  "Al jouw Bombyxx bestellingen op een rij. Hier kun je ze inzien en opnieuw bestellen.";
export const META_NOT_FOUND_DESCRIPTION =
  "Oeps, je bent naar een pagina gegaan die bij ons niet (meer) bestaat.";

export const META_STORE_LOCATOR_DESCRIPTION = "Onze store locator.";
export const META_B2B_SHOWROOM_DESCRIPTION = "Onze showroom.";
export const META_GET_INSPIRED_DESCRIPTION = "Word geïnspireerd.";
export const META_VACATURES_DESCRIPTION =
  "Bombyxx B.V. is een internationale groothandel in luxe woonaccessoires, gedreven door passie voor kwaliteit en stijlvol design. Met ons kantoor & magazijn in Enkhuizen en showroom & magazijn in Aalsmeer, bedienen we een wereldwijd publiek met verfijnde smaken.";

export const META_CATEGORY_SLOGAN = "Puur Vakmanschap";
