import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { useTranslation } from "react-i18next";
import urls from "../../urls";
import { sumBy } from "lodash";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { useCart } from "../hooks/firestore/useCart";
import { useUserProfile } from "../hooks/firestore/useUserProfile";
import { useUser } from "reactfire";
import CartIcon from "../icons/CartIcon";
import WishlistIcon from "../icons/WishlistIcon";
import AccountIcon from "../icons/AccountIcon";

const IconGroup = ({
  wishlistData,
  iconWhiteClass,
  mobile = false,
  homeLayout = false,
}) => {
  const menuCartRef = useRef();
  const cartBtnRef = useRef();
  const accountCardRef = useRef();
  const accountBtnRef = useRef();
  const { data: profile, profileLoading } = useUserProfile();
  const { data: auth } = useUser();
  const { data: cart } = useCart();
  const cartItems = !!cart && !!cart.products ? cart.products : [];
  const hidePrices = !!((!!profile && profile.hidePrices) || profileLoading);
  const isSalesPerson = !!profile && profile.isSalesPerson;

  useEffect(() => {
    document.addEventListener("click", handleScreenClick);
    return () => document.removeEventListener("click", handleScreenClick);
  }, []);

  /** Close cart modal if it is open and user clicks outside of the element*/
  const handleScreenClick = (e) => {
    // Close Cart Modal if opened
    if (!!menuCartRef.current && !!cartBtnRef.current) {
      if (
        !!menuCartRef.current &&
        !menuCartRef.current.contains(e.target) &&
        !cartBtnRef.current.contains(e.target) &&
        menuCartRef.current.className.includes("active") &&
        e.target.id !== "delete-cart-item"
      ) {
        menuCartRef.current.classList.remove("active");
      }
    }
    // Close Account Modal if opened
    if (!!accountCardRef.current && !!accountBtnRef.current) {
      if (
        !!accountCardRef.current &&
        !accountCardRef.current.contains(e.target) &&
        !accountBtnRef.current.contains(e.target) &&
        accountCardRef.current.className.includes("active")
      ) {
        accountCardRef.current.classList.remove("active");
      }
    }
  };

  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu",
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const { t } = useTranslation();

  const cartItemsAmount = useMemo(
    () => sumBy(cartItems, "quantity"),
    [cartItems],
  );

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      <div
        className="same-style account-setting d-none d-lg-flex"
        style={{ gap: 24 }}
      >
        <button
          className="account-setting-active p-0"
          onClick={(e) => handleClick(e)}
          ref={accountBtnRef}
        >
          <AccountIcon />
        </button>
        <div className="account-dropdown" ref={accountCardRef}>
          {!!auth && !!auth.uid ? (
            <ul>
              <li>
                <Link to={urls.my_account}>{t("my_account")}</Link>
              </li>
              <li>
                <Link to={urls.my_orders}>{t("my_orders")}</Link>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link to={{ pathname: urls.login_register, navKey: "login" }}>
                  {t("login")}
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: urls.login_register, navKey: "register" }}
                >
                  {t("register")}
                </Link>
              </li>
            </ul>
          )}
        </div>
        {!isSalesPerson && !mobile && !hidePrices && (
          <>
            <div className="same-style header-wishlist d-none d-lg-block">
              <Link to={urls.wishlist}>
                <WishlistIcon />
                <Counter>
                  <span>
                    {wishlistData && wishlistData.length
                      ? wishlistData.length
                      : 0}
                  </span>
                </Counter>
              </Link>
            </div>
          </>
        )}
        <div className="same-style cart-wrap d-none d-lg-block">
          <button
            className="icon-cart"
            onClick={(e) => handleClick(e)}
            ref={cartBtnRef}
          >
            <CartIcon />
            <Counter>
              <span>{cartItemsAmount}</span>
            </Counter>
          </button>
          <MenuCart reference={menuCartRef} />
        </div>
        <div className="same-style mobile-off-canvas d-block d-lg-none">
          <button
            className="mobile-aside-button"
            onClick={() => triggerMobileMenu()}
          >
            <i className="pe-7s-menu" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Counter = styled.div`
  display: flex;
  font-size: 11px;
  line-height: 16px;
  position: absolute;
  top: -8px;
  right: -9px;
  width: 16px;
  height: 16px;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background-color: ${colors.cta_btn};

  span {
    align-self: center;
  }
`;

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    wishlistData: state.wishlistData,
  };
};

export default connect(mapStateToProps, null)(IconGroup);
