export const DESCRIPTION_LENGTH = 150;
export const CATEGORY = "category";
export const SEARCH = "search";
export const PAGINATION = "pagination";
export const PRICEFILTER = "priceFilter";
export const PRICE_RANGE_FILTER = "priceRange";
export const COLORS_FILTER = "colors";
export const HEIGHTS_FILTER = "heights";
export const DIAMETERS_FILTER = "diameters";
export const NEW_FILTER = "new";
export const PRE_ORDER_FILTER = "preOrder";
export const SALE_ONLY_FILTER = "saleOnly";
export const DEFAULT = "default";
export const PRODUCT_PLACEHOLDER_SRC =
  process.env.REACT_APP_FIREBASE_STORAGE_BASE_URL +
  "/o/global%2Fplaceholder.jpg?alt=media";
export const PRODUCT_PLACEHOLDER_LOCAL_SRC =
  "/assets/img/product/placeholder.jpg";
export const CATEGORY_PLACEHOLDER_SRC = "assets/img/categories/placeholder.jpg";
export const RELATED_PRODUCTS_IMG_WIDTH = 250;

export const PRODUCT_COLORS = [
  { name: "Black", id: "BLACK", img: "/assets/img/products/colors/black.webp" },
  { name: "Blue", id: "BLUE", img: "/assets/img/products/colors/blue.webp" },
  { name: "Brown", id: "BROWN", img: "/assets/img/products/colors/brown.webp" },
  {
    name: "Butter",
    id: "BUTTER",
    img: "/assets/img/products/colors/butter.webp",
  },
  {
    name: "Dark Smoke",
    id: "DARK SMOKE",
    img: "/assets/img/products/colors/dark_smoke.webp",
  },
  { name: "Green", id: "GREEN", img: "/assets/img/products/colors/green.webp" },
  { name: "Grey", id: "GREY", img: "/assets/img/products/colors/grey.webp" },
  {
    name: "Indigo / Smoke",
    id: "INDIGO",
    img: "/assets/img/products/colors/indigo.webp",
  },
  { name: "Pink", id: "PINK", img: "/assets/img/products/colors/pink.webp" },
  { name: "Smoke", id: "SMOKE", img: "/assets/img/products/colors/smoke.webp" },
  {
    name: "Smoke/Grey",
    id: "SMOKE_GREY",
    img: "/assets/img/products/colors/smoke_grey.webp",
  },
  { name: "White", id: "WHITE", img: "/assets/img/products/colors/white.webp" },
  {
    name: "Yellow / Gold",
    id: "GOLD",
    img: "/assets/img/products/colors/gold.webp",
  },
];

export const PRODUCT_HEIGHTS = [
  [0, 15],
  [15, 30],
  [30, 45],
  [45, 65],
];

export const PRODUCT_DIAMETERS = [
  [0, 15],
  [15, 30],
  [30, 45],
  [45, 65],
];
