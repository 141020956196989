import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urls, { home } from "../../urls";
import styled from "styled-components";
import { WEBSITE_LOGO } from "../../constants/CompanyConstants";
import { Col, Row } from "react-bootstrap";
import { device } from "../../utils/breakpoints";

const NavMenuHome = () => {
  const { t } = useTranslation();
  return (
    <MainMenu className={"main-menu home"}>
      <nav className={"home"}>
        <ul className={"home"}>
          <TopLogoRow xs={12}>
            <Link to={home}>
              <Logo src={WEBSITE_LOGO} />
            </Link>
          </TopLogoRow>
          <NavColumn md={6} xl={5}>
            <li>
              <NavLink to={urls.home} activeClassName="active" exact>
                {t("home")}
              </NavLink>
            </li>
            <li>
              <NavLink to={urls.about} activeClassName="active" exact>
                {t("about_us")}
              </NavLink>
            </li>
            <li>
              <NavLink to={urls.get_inspired} activeClassName="active" exact>
                {t("get_inspired")}
              </NavLink>
            </li>
          </NavColumn>
          <LogoColumn xs={0} xl={2} className={"align-self-center"}>
            <Link to={home}>
              <Logo src={WEBSITE_LOGO} />
            </Link>
          </LogoColumn>

          <NavColumn md={6} xl={5}>
            <li>
              <NavLink to={urls.b2b_showroom} activeClassName="active" exact>
                {t("b2b_showroom")}
              </NavLink>
            </li>
            <li>
              <NavLink to={urls.store_locator} activeClassName="active" exact>
                {t("store_locator")}
              </NavLink>
            </li>
            <li>
              <NavLink to={urls.contact} activeClassName="active" exact>
                {t("contact_us")}
              </NavLink>
            </li>
          </NavColumn>
        </ul>
      </nav>
    </MainMenu>
  );
};

const MainMenu = styled.div`
  @media ${device.lg} {
    height: 92px;
  }
`;

const TopLogoRow = styled(Row)`
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  @media ${device.xl} {
    display: none;
  }
`;

const NavColumn = styled(Col)`
  align-self: center;
  @media ${device.maxXl} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const LogoColumn = styled(Col)`
  display: none;
  @media ${device.xl} {
    display: block;
  }
`;

const Logo = styled.img`
  max-width: 12vw;
`;

export default NavMenuHome;
