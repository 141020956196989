import { useEffect, useState } from "react";
import axios from "axios";
import { create_session, order_confirmed, update_session } from "../../urls";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useUser } from "reactfire";
import { useCart } from "../hooks/firestore/useCart";

const Session = () => {
  const { data: auth } = useUser();
  const { data: cart } = useCart();
  const cartItems = !!cart && !!cart.products ? cart.products : [];
  const [start, setStart] = useState(null);
  const [ordered, setOrdered] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === order_confirmed) {
      setOrdered(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !!auth?.uid &&
      !start &&
      !process.env.REACT_APP_API_URL.includes("localhost")
    ) {
      const startTime = moment().unix();
      setStart(startTime);
      createSession(startTime);
    }
  }, [auth]);

  useEffect(() => {
    if (!!start) updateSession();
  }, [ordered, cartItems, pathname]);

  const createSession = (startTime) => {
    if (!!auth && !!auth.uid) {
      const object = {
        start: startTime,
        initialPath: window.location.pathname,
        userId: auth.uid,
      };
      axios.post(create_session, object).catch((err) => console.log(err));
    }
  };

  const updateSession = () => {
    if (!!auth && !!auth.uid) {
      const object = {
        start: start,
        products: cartItems,
        userId: auth.uid,
        ordered: ordered,
        exitPath: pathname,
        end: moment().unix(),
      };
      axios.post(update_session, object).catch((err) => console.log(err));
    }
  };

  return null;
};

export default Session;
