import PropTypes from "prop-types";
import React, { Fragment } from "react";
import HeaderHome from "../wrappers/header/HeaderHome";
import FooterHome from "../wrappers/footer/FooterHome";
import styled from "styled-components";
import { device } from "../utils/breakpoints";

const Layout = ({
  children,
  headerContainerClass,
  headerTop,
  headerPaddingClass,
  headerPositionClass,
}) => {
  return (
    <Fragment>
      <HeaderHome
        layout={headerContainerClass}
        top={headerTop}
        headerPaddingClass={headerPaddingClass}
        headerPositionClass={headerPositionClass}
      />
      <StyledMain>{children}</StyledMain>
      <FooterHome spaceTopClass="pt-70" spaceBottomClass="pb-50" />
    </Fragment>
  );
};

const StyledMain = styled.main`
  padding-top: 96px;

  @media ${device.lg} {
    padding-top: 138px;
  }
`;

Layout.propTypes = {
  children: PropTypes.any,
  headerContainerClass: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  headerTop: PropTypes.string,
};

export default Layout;
