import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass, link, closeMenu }) => {
  return (
    <div
      className={`${logoClass ? logoClass : ""}`}
      onClick={closeMenu}
      style={{ display: "contents" }}
    >
      <Link to={!!link ? link : "/"} style={{ display: "contents" }}>
        <img alt="logo" src={imageUrl} />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;
