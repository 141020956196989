import { useFirestore, useFirestoreDocData, useUser } from "reactfire";
import { doc } from "firebase/firestore";
import { FIRESTORE_USERS_TABLE } from "../../../constants/FirebaseConstants";

export function useUserProfile() {
  const firestore = useFirestore();
  const { data: user } = useUser();
  const docRef = doc(firestore, FIRESTORE_USERS_TABLE, user?.uid || "default");
  const { data, status } = useFirestoreDocData(docRef, { idField: "uid" });
  const isLoading = status === "loading" || !user;

  return { data, isLoading };
}
