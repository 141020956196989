import React from "react";
import { useTranslation } from "react-i18next";
import { WEBSITE_NAME } from "../../constants/CompanyConstants";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { device } from "../../utils/breakpoints";
import { privacy, terms_conditions } from "../../urls";
import { Link } from "react-router-dom";
import { colors } from "../../utils/theme";
import { transparentize } from "../../utils/helperFn";

const FooterBottom = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer fluid>
      <Wrapper>
        <Column lg={4}>
          <TextBold>{WEBSITE_NAME}</TextBold>
        </Column>
        <Column lg={4}>
          <StyledLink to={terms_conditions}>
            {t("terms_and_conditions")}
          </StyledLink>
        </Column>
        <Column lg={4}>
          <StyledLink to={privacy}>{t("privacy_policy")}</StyledLink>
        </Column>
      </Wrapper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  border-top: 0.3px solid ${transparentize(colors.primaryDark, 0.1)};
`;

const Column = styled(Col)`
  display: flex;
  justify-content: center;
`;

const StyledDownloadLink = styled.a`
  font-weight: 300;
  margin-bottom: 0.9rem;
  color: ${colors.primaryDark};
`;

const StyledLink = styled(Link)`
  font-weight: 300;
  margin-bottom: 0.9rem;
  color: ${colors.primaryDark};
`;

const Text = styled.p`
  font-size: 1rem;
  margin-bottom: 0.9rem !important;
`;

const TextBold = styled(Text)`
  font-weight: 600;
`;

const Wrapper = styled(Row)`
  padding: 30px 60px 10px 60px;
  @media ${device.maxLg} {
    padding: 30px 40px 10px 40px;
  }
`;

export default FooterBottom;
