import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";

export const Loader = () => {
  return (
    <Wrapper>
      <img src={"/assets/img/logo/logo.png"} alt={"Bombyxx logo"} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  width: 100vw;
  height: calc(100vh + 126px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;
  margin-top: -110px;

  img {
    -webkit-animation: pulse 1.2s infinite;
    -moz-animation: pulse 1.2s infinite;
    animation: pulse 1.2s infinite;
    max-width: 200px;
  }

  @media ${device.lg} {
    margin-top: -120px;
    img {
      max-width: 300px;
    }
  }

  @-moz-keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
`;
